import Vue from "vue";

const baseURL = process.env.VUE_APP_HARBOR_API_URL;
const gitlabURL = process.env.VUE_APP_GITLAB_API_URL;

class GitlabService {
    static Register(payload) {
        const url = `${baseURL}/gitlab/user`;
        return Vue.axios.post(
            url, payload
        );
    }

    static GetUserInfo(params) {
        console.log(params)
        const url = `${baseURL}/gitlab/user`;
        return Vue.axios.get(
            url, {params}
        );
    }

    static GetProjectStats(params) {
        const url = `${baseURL}/${params}/stats`;
        return Vue.axios.get(
            url, {params}
        );
    }

    static GetGitlabProjects() {
        const url = `${gitlabURL}/gitlab`;
        return Vue.axios.get(
            url
        );
    }

}

export default GitlabService;
