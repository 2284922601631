<template>
  <div class="text-primary">
    <PageHeader title="Dashboard"/>
    <div id="registration-container" class="margin-under-header">
      <common-loading v-if="loading"/>
      <div class="container-fluid">
        <div class="row">
          <div class="col-10">
            <h4 class="fw-bold">Live Utilization</h4>
          </div>
          <div class="col-2">
            <base-select
                :options="refreshOptions"
                class="mb-0"
                :value="selectedRefresh"
                @change-value="changeRefreshRate"
                cus_style="height:43px"
            ></base-select>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-3">
            <b-card class="text-primary" style="min-height: 300px">
              <h6 class="mt-2 ms-2 fw-bold text-primary">GPUs</h6>
              <div class="ms-3">
                <div class="d-flex align-items-end">
                  <div class="number fw-bold h2 m-0 lh-1 me-2">{{ this.gpuDetails.gpuCount }}</div>
                  <div class="">GPUs</div>
                </div>
                <div class="mt-3" :title="nameArrayFormat(gpuDetails.gpuNames)">{{ this.gpuDetails.gpuNames.length===1? this.gpuDetails.gpuNames[0]: "Various" }}</div>
              </div>
              <div class="row ms-3 border-bottom-lightgray me-3">
                <div class="col-6">
                  <div class="h6 fw-bold text-primary">VRAM</div>
                  <apex-chart
                      style="margin:0; padding:0;"
                      v-if="!loading"
                      height="130" type="radialBar" :options="vramOptions"
                      :series="[Math.round(this.gpuDetails.vram.usagePercentage) || 0]">asd
                  </apex-chart>
                </div>
                <div class="col-6">
                  <div class="h6 text-primary fw-bold">Compute</div>
                  <apex-chart height="130" type="radialBar" :options="gpuComputeOptions"
                              :series="[this.gpuDetails.compute]">asd
                  </apex-chart>
                </div>
              </div>
              <div class="d-flex mt-3 ms-3">
                <div style="height: 24px; width: 24px; border:1px solid #5AE0BB; margin-right:10px;"

                     v-for="gpu of this.gpuDetails.gpusCompute" :key="gpu">
                  <div
                      :style="{'opacity': gpu.compute/100}"
                      style="background-color:rgb(15, 169, 126)"
                      class="w-100 h-100"
                  ></div>

                </div>
              </div>
            </b-card>
          </div>
          <div class="col-9">
            <b-card class="text-primary" style="min-height: 300px">
              <h6 class="mt-2 ms-2 text-primary fw-bold">Nodes</h6>
              <div class="ms-3">
                <div class="d-flex align-items-end">
                  <div class="number fw-bold h2 m-0 lh-1 me-2">{{ this.metrics.nodeCount }}</div>
                  <div class="">Nodes</div>
                </div>
              </div>
              <hr class="mb-2" style="background: #ECEAE2;opacity:0.15;"/>
              <div class="row mt-3">
                <div class="col-4 border-right">
                  <h6 class="text-primary ms-2 fw-bold">CPU</h6>
                  <div class="ms-3">
                    <div class="d-flex align-items-end">
                      <div class="number fw-bold h2 m-0 lh-1 me-2">{{
                          Math.round(this.metrics.cpu.usagePercentage || 0)
                        }}%
                      </div>
                      <div class="">Compute Usage</div>
                    </div>
                    <div class="mt-2 font-size-14px" :title="this.metrics.cpu.name">{{ this.metrics.cpu.name.length===1? this.metrics.cpu.name[0]: 'Various' }}</div>
                    <div class="row">
                      <div class="col-2">
                        <div class="mt-3 font-size-12px">{{
                            Math.round(this.metrics.cpu.usagePercentage || 0, 2)
                          }}%
                        </div>
                      </div>
                      <div class="col-10">
                        <b-progress class="bg-secondary mt-4" :max="max" height="8px">
                          <b-progress-bar variant="primary" :value="this.metrics.cpu.usagePercentage"></b-progress-bar>
                        </b-progress>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 border-right">
                  <h6 class="text-primary ms-2 fw-bold ">RAM</h6>
                  <div class="ms-3">
                    <div class="d-flex align-items-end">
                      <div class="number fw-bold h2 m-0 lh-1 me-2">{{
                          Math.round(this.metrics.ram.used || 0, 2)
                        }}GB
                      </div>
                      <div class="">used / {{ Math.round(this.metrics.ram.total || 0, 2) }}GB Total</div>
                    </div>
                    <div class="mt-2 font-size-14px" style="height:21px;" :title="this.metrics.ram.name"></div>
                    <div class="row">
                      <div class="col-2">
                        <div class="mt-3 font-size-12px">{{
                            Math.round(this.metrics.ram.usagePercentage || 0, 2)
                          }}%
                        </div>

                      </div>
                      <div class="col-10">
                        <b-progress class="bg-secondary mt-4" :max="max" height="8px">
                          <b-progress-bar :value="this.metrics.ram.usagePercentage" variant="primary"></b-progress-bar>
                        </b-progress>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="col-4">
                  <h6 class="text-primary ms-2 fw-bold">Disk</h6>
                  <div class="ms-3">
                    <div class="d-flex align-items-end">
                      <div class="number fw-bold h2 m-0 lh-1 me-2">{{ this.metrics.disk.used?.toFixed(3) }}TB
                      </div>
                      <div class="">used / {{ this.metrics.disk.total.toFixed(2) }}TB Total</div>
                    </div>
                    <div class="mt-2 font-size-14px" :title="this.metrics.disk.name.join('\n')">{{ this.metrics.disk.name.length===1? this.metrics.disk.name[0]: 'Various' }}</div>
                    <div class="row">
                      <div class="col-2">
                        <div class="mt-3 font-size-12px">{{
                            Math.round(this.metrics.disk.usagePercentage || 0, 2)
                          }}%
                        </div>
                      </div>
                      <div class="col-10">

                        <b-progress class="bg-secondary mt-4" :max="max" height="8px">
                          <b-progress-bar :value="this.metrics.disk.usagePercentage" variant="primary"></b-progress-bar>
                        </b-progress>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </b-card>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-10">
            <h4 class="fw-bold">Live Utilization</h4>
          </div>
          <div class="col-2">
            <base-select
                :options="historicalOptions"
                class="mb-0"
                :value="selectedHistorical"
                @change-value="setHistorical"
                cus_style="height:43px"
            ></base-select>
          </div>
        </div>
        <b-card class="mt-2 mb-5">
          <div class="d-flex border-bottom-gray mb-5">
            <div class="px-5 py-3 border-bottom-gray fs-5 fw-bold"
                 v-on:click="setActiveGraph('CPU')"
                 :class="{'active-graph':activeGraph==='CPU','text-deactivated':activeGraph!=='CPU'}">CPU
            </div>
            <div class="px-5 py-3 border-bottom-gray fs-5 fw-bold"
                 v-on:click="setActiveGraph('GPU')"
                 :class="{'active-graph':activeGraph==='GPU','text-deactivated':activeGraph!=='GPU'
            }">GPU
            </div>
            <div class="px-5 py-3 border-bottom-gray fs-5 fw-bold"
                 v-on:click="setActiveGraph('RAM')"
                 :class="{'active-graph':activeGraph==='RAM','text-deactivated':activeGraph!=='RAM'}">RAM
            </div>
            <div class="px-5 py-3 border-bottom-gray fs-5 fw-bold"
                 v-on:click="setActiveGraph('Disk')"
                 :class="{'active-graph':activeGraph==='Disk','text-deactivated':activeGraph!=='Disk'}">Disk
            </div>
          </div>

          <DynamicGraph :current-graph-options="currentGraphOptions"/>

        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader.vue";
import PortalService from "@/services/portal.service";
import DynamicGraph from "@/pages/Landing/DynamicGraph.vue";

export default {
  name: 'Main-Dashboard',
  components: {DynamicGraph, PageHeader},
  mounted() {
    this.getResourcesData();
    this.polling = setInterval(() => {
      this.getResourcesData()
    }, 30 * 1000)
  },
  destroyed() {
    clearInterval(this.polling);
  },
  methods: {
    nameArrayFormat(array){
      let names=''
      for(let name of array){
        names+= name
        names+=' '
      }
      return names
    },
    changeRefreshRate(value) {
      this.selectedRefresh = value;
      let interval = this.selectedRefresh;
      clearInterval(this.polling);
      this.polling = setInterval(() => {
        this.getResourcesData()
      }, interval * 1000)
    },
    setActiveGraph(graph) {
      this.activeGraph = graph;
      this.renderGraph()
    },
    updateDiskUtilization(diskData) {
      const series = diskData.map(item => ({
        name: item.metric.instance,
        data: item.values.map(value => ({
          x: new Date(value[0] * 1000).toLocaleString(), // Convert UNIX timestamp to JavaScript Date
          y: parseFloat(value[1]).toFixed(2)        // Convert string to number
        }))
      }));
      this.diskOptions = null;
      this.diskOptions = {
        chart: {
          type: 'line',
          height: 350
        },
        series: series,
        xaxis: {
          type: 'datetime',
          title: {
            text: 'Timestamp'
          }
        },
        yaxis: {
          title: {
            text: 'Disk Usage'
          }
        },
        title: {
          text: 'Disk Usage Over Time',
          align: 'center'
        }
      };
    },
    updateRamUtilization(ramData) {
      const series = ramData.map(item => ({
        name: item.metric.instance,
        data: item.values.map(value => ({
          x: new Date(value[0] * 1000).toLocaleString(), // Convert UNIX timestamp to JavaScript Date
          y: parseFloat(value[1]).toFixed(2)        // Convert string to number
        }))
      }));
      this.ramOptions = {
        chart: {
          type: 'line',
          height: 350
        },
        series: series,
        xaxis: {
          type: 'datetime',
          title: {
            text: 'Timestamp'
          }
        },
        yaxis: {
          title: {
            text: 'Memory Usage'
          }
        },
        title: {
          text: 'Memory Usage Over Time',
          align: 'center'
        }
      };
    },
    updateCPUUtilization(cpuData) {
      const series = cpuData.map(item => ({
        name: item.metric.instance,
        data: item.values.map(value => ({
          x: new Date(value[0] * 1000).toLocaleString(), // Convert UNIX timestamp to JavaScript Date
          y: parseFloat(value[1]).toFixed(2)        // Convert string to number
        }))
      }));
      this.cpuOptions = {
        chart: {
          type: 'line',
          height: 350
        },
        series: series,
        xaxis: {
          type: 'datetime',
          title: {
            text: 'Timestamp'
          }
        },
        yaxis: {
          title: {
            text: 'CPU Compute'
          }
        },
        title: {
          text: 'CPU Compute Over Time',
          align: 'center'
        }
      };
    },
    updateGPUUtilization(gpuData) {
      const series = gpuData.map(item => ({
        name: item.metric.instance,
        data: item.values.map(value => ({
          x: new Date(value[0] * 1000).toLocaleString(), // Convert UNIX timestamp to JavaScript Date
          y: parseFloat(value[1]).toFixed(2)        // Convert string to number
        }))
      }));
      this.gpuOptions = {
        chart: {
          type: 'line',
          height: 350
        },
        series: series,
        xaxis: {
          type: 'datetime',
          title: {
            text: 'Timestamp'
          }
        },
        yaxis: {
          title: {
            text: 'GPU'
          }
        },
        title: {
          text: 'GPU Over Time',
          align: 'center'
        }
      };
    },
    renderGraph() {
      if (this.activeGraph === 'CPU') {
        this.currentGraphOptions = this.cpuOptions
      } else if (this.activeGraph === 'GPU') {
        this.currentGraphOptions = this.gpuOptions
      } else if (this.activeGraph === 'Disk') {
        this.currentGraphOptions = this.diskOptions
      } else if (this.activeGraph === 'RAM') {
        this.currentGraphOptions = this.ramOptions
      }
    },
    setHistorical(value) {
      this.selectedHistorical = value;
      this.getResourcesData();
      this.renderGraph();
    },
    getResourcesData() {
      PortalService.getGPUDetails().then(({data}) => {
        this.gpuDetails = data
        this.vramOptions.chart.total = this.gpuDetails.vram.total;
        PortalService.getMetrics().then(({data}) => {
          this.metrics = data
          PortalService.getUtilization({interval: this.selectedHistorical}).then(({data}) => {
            this.updateDiskUtilization(data.disk);
            this.updateCPUUtilization(data.cpu)
            this.updateRamUtilization(data.ram)
            this.updateGPUUtilization(data.gpu)
            this.renderGraph()
            this.loading = false;
          })
        })
      })
    },
  },
  data: function () {
    return {
      activeGraph: 'CPU',
      selectedHistorical: 'LAST_12_HOUR',
      historicalOptions: [
        {id: 'LAST_12_HOUR', label: 'Last 12hrs'},
        {id: 'LAST_24_HOUR', label: 'Last 24hrs'}
      ],
      polling: null,

      vramOptions: {
        chart: {
          parentHeightOffset:15,
          offsetX: -30,
          offsetY: -20,
        },
        plotOptions: {

          radialBar: {
            dataLabels: {
              show: true,
              name: {
                show: true,
                fontSize: '11px',
                fontFamily: undefined,
                fontWeight: 400,
                color: '#0C5966',
                offsetY: 15,
                formatter: function (params, params2, params3) {
                  let total = (params3.config?.chart?.total / 1000).toFixed(1)
                  return total + 'GB'
                }
              },
              value: {
                show: true,
                fontSize: '14px',
                fontFamily: undefined,
                fontWeight: 600,
                color: undefined,
                offsetY: -15,
                formatter: function (val, opts) {
                  let total = opts.config?.chart?.total
                  return (val / 100 * total / 1000).toFixed(1) + "GB"
                }
              },
              total: {
                show: false,
                label: 'Total',
                color: '#373d3f',
                fontSize: '16px',
                fontFamily: undefined,
                fontWeight: 600,
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => {
                    return a + b
                  }, 0) / w.globals.series.length + '%'
                }
              }
            },

            track: {
              background: "#B8F2E2"
            },
            startAngle: -150,
            endAngle: 150,
          },
        },
        colors: ['#021214'],
        fill: {
          colors: ['#0FA97E'],
        },

      },
      gpuComputeOptions: {
        chart: {
          offsetX: -20,
          offsetY: -20,
        },
        plotOptions: {

          radialBar: {
            dataLabels: {
              show: true,
              name: {
                show: false,
                fontSize: '12px',
                fontFamily: undefined,
                fontWeight: 400,
                color: '#0C5966',
                offsetY: 15,
              },
              value: {
                show: true,
                fontSize: '16px',
                fontFamily: undefined,
                fontWeight: 600,
                color: undefined,
                offsetY: 5,
              }
            },

            track: {
              background: "#B8F2E2"
            },
            startAngle: -150,
            endAngle: 150,
          },
        },
        colors: ['#021214'],
        fill: {
          colors: ['#0FA97E'],
        },

      },
      metrics: {},
      gpuDetails: {},
      selectedRefresh: 30,
      loading: true,
      refreshOptions: [
        {id: 30, label: 'Refresh Rate (30s)'},
        {id: 60, label: 'Refresh Rate (1Min)'},
        {id: 60 * 5, label: 'Refresh Rate (5Mins)'}

      ],
      diskOptions: {},
      ramOptions: {},
      cpuOptions: {},
      gpuOptions: {},
      currentGraphOptions: {}

    }
  }
};
</script>

<style scoped>

#registration-container {
  padding: 0px 25px;
}

#registration-container .tab {
  background-color: #89E9CF;
  border-radius: 4px;
  color: #112849;
  text-align: center;
  padding: 15px;
  cursor: pointer;
}

#registration-container .tab .label {

  font-size: 14px;
}

#registration-container .tab .value {

  font-size: 26px;
}

#registration-container .tab .value .red-label {

  font-size: 14px;
  color: #ce1a36;
  margin-left: 0.25rem;
}

#registration-container .tab .value .red-label img {
  margin-right: 0.15rem;
  margin-top: -1px;
}

.border-right {
  border-right: 1px solid #ECEAE2;
}

.font-size-14px {
  font-size: 14px;
}

.font-size-12px {
  font-size: 12px;
}

.bg-primary {
  background-color: #0C5966 !important;
}

.bg-secondary {
  background-color: #E7FBF5 !important;
}

.text-primary {
  color: #062D33 !important;
}

.border-bottom-gray {
  border-bottom: 1px solid #7E828280;
}

.border-bottom-lightgray {
  border-bottom: 1px solid #7E828280;
}

.text-deactivated {
  color: #7E828280;
}

.active-graph {
  border-bottom: 2px solid black;
}
</style>