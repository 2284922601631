import Vue from "vue";

const baseURL = process.env.VUE_APP_PORTAL_API_URL;

class PortalService {


    static getUsers(params) {
        const url = `${baseURL}/users`;
        return Vue.axios.get(
            url, {params}
        );
    }
    static getCurrentUser() {
        const url = `${baseURL}/users/info`;
        return Vue.axios.get(
            url
        );
    }

    static toggleUserActive(userId, value) {
        const url = `${baseURL}/users/${userId}/activate/${value}`;
        return Vue.axios.put(
            url
        );
    }

    static getUserDetail(userId) {
        const url = `${baseURL}/users/${userId}`;
        return Vue.axios.get(
            url
        );
    }

    static getModuleGroup() {
        const url = `${baseURL}/modules/gruops`;
        return Vue.axios.get(
            url
        );
    }

    static updateUser(userId, userData) {
        const url = `${baseURL}/users/${userId}`;
        return Vue.axios.post(
            url, userData
        );
    }

    static inviteUser(userData) {
        const url = `${baseURL}/users`;
        return Vue.axios.post(
            url, userData
        );
    }

    static getGPUDetails(){
        const url =`${baseURL}/dashboard/gpu`;
        return Vue.axios.get(url)
    }

    static getMetrics(){
        const url =`${baseURL}/dashboard/metrics`;
        return Vue.axios.get(url)
    }

    static getUtilization(params){
        const url =`${baseURL}/dashboard/utilization`;
        return Vue.axios.get(url, {params})
    }

    static getRoleOptions(){
        const url =`${baseURL}/roles/dll`;
        return Vue.axios.get(url)
    }

    static getModuleOptions(){
        const url =`${baseURL}/modules`;
        return Vue.axios.get(url)
    }

}

export default PortalService;
