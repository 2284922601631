import Vue from "vue";

const baseURL = process.env.VUE_APP_TRITON_API_URL;

class TritonInferenceService {
  static GetModels = ({ page, pageSize, search }) => {
    const url = `${baseURL}/triton/models?pageIndex=${page}&pageSize=${pageSize}`;

    if (search) {
      return Vue.axios.get(`${url}&q=${search}`);
    } else {
      return Vue.axios.get(url);
    }
  };

  static GetModelById = (modelId) => {
    return Vue.axios.get(`${baseURL}/triton/models/${modelId}`, {});
  };

  static UploadFile(file, onProgress) {
    return Vue.axios.post(`${baseURL}/triton/upload`, file, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      timeout: 0,
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        onProgress(percentCompleted);
      },
    });
  }

  static CreateModel = (modelData) => {
    return Vue.axios.post(`${baseURL}/triton/models`, modelData, {
      headers: {
        "content-type": "application/json",
      },
    });
  };

  static optimizeModel = (modelId) => {
    return Vue.axios.post(`${baseURL}/triton/models/${modelId}`);
  };

  static DeleteModel = (modelId) => {
    return Vue.axios.delete(`${baseURL}/triton/models/${modelId}`);
  };
}

export default TritonInferenceService;
